@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  overflow-x: hidden;
  margin: 0;
}

.word-spacing {
  word-spacing: 0.25rem;
}

table {
  border: 1px solid #f95700;

  border-collapse: collapse;
}

.table-rounded {
  border-radius: 20px; /* Adjust the value to change the roundness */
  overflow: hidden; /* Ensure the border-radius clips the table corners */
}
th {
  border: 1px solid #f95700;
  background-color: #f95700;
  color: white;
}

tr {
  border: 1px solid #f95700;
  margin: 40px 20px;
}

td {
  padding: 10px 10px;
}

.cut-text {
  text-decoration: line-through;
}

table tr td:first-child {
  padding-left: 25px;
  /* Adjust the padding value as needed */
}

td:nth-child(3) {
  background-color: #fefae7;
  border-right: 1px solid #f95700;
}

.heading-color {
  background-color: #fefae7;
  padding: 15px;
  transform: scaleY(1.3);
  border-radius: 15px;
}
.left-align {
  text-align: left;
  padding-left: 2rem;
}

.floating-box {
  gap: 20px;
  padding: 20px;
  width: 90vw;
  height: auto;
  background-color: #fefae7;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  margin: 30px auto;
}

.back {
  z-index: 1;
  position: relative;
}

.word-spacing {
  word-spacing: 0.25rem;
}

.k {
  position: absolute;
  left: 15.5rem;
  top: 16.5rem;
  z-index: -1;
}
.d {
  z-index: -1;
}

.index {
  z-index: 1;
  position: relative;
}
.cut-text {
  text-decoration: line-through;
}

.bg-image {
  background-image: url("./img/Component 12.svg");
}

.left-align {
  text-align: left;
  padding-left: 2rem;
}
